import React from "react"
import Layout from "../../components/common/Layout"
import ActionBar from "../../components/actionBar/ActionBar"
import ChooseStyle from "../../components/sections/ChooseStyle"
import styled from "styled-components"

const Gallery = () => {
  return (
    <Layout>
      <ActionBar />
      <Wrapper>
        <ChooseStyle />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div``
export function Head() {
  return <title>Gallery</title>
}

export default Gallery
